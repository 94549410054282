interface FilterDropdownProps {
  id: string
  label: string
  options: string[]
  handleFilterChange: (filter: React.ChangeEvent<HTMLSelectElement>) => void
}

function formatStringForId(str: string) {
  return str
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
}

function shortLabelToFullLabel(label: string) {
  switch (label) {
    case 'CR':
      return 'Herausforderungswert'
    case 'STR':
      return 'Stärke'
    case 'GES':
      return 'Geschicklichkeit'
    case 'KON':
      return 'Konstitution'
    case 'INT':
      return 'Intelligenz'
    case 'WEI':
      return 'Weisheit'
    case 'CHA':
      return 'Charisma'
    default:
      return label
  }
}

function FilterDropdown({
  id,
  label,
  options,
  handleFilterChange,
}: FilterDropdownProps) {
  return (
    <div className='flex flex-col w-full'>
      <label htmlFor={id} className='mb-1 text-gray-700'>
        {shortLabelToFullLabel(label)}
      </label>
      <select
        id={id}
        name={id}
        className='h-full p-2 border border-gray-300 rounded'
        onChange={(e) => handleFilterChange(e)}
      >
        <option value=''>{shortLabelToFullLabel(label)}</option>
        {options.map((option) => (
          <option
            value={formatStringForId(option)}
            key={formatStringForId(option)}
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default FilterDropdown
