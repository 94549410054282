export function shortLabelToFullLabel(label: string) {
  switch (label) {
    case 'CR':
      return 'Herausforderungswert'
    case 'Size':
      return 'Größe'
    case 'STR':
      return 'Stärke'
    case 'GES':
      return 'Geschicklichkeit'
    case 'KON':
      return 'Konstitution'
    case 'INT':
      return 'Intelligenz'
    case 'WEI':
      return 'Weisheit'
    case 'CHA':
      return 'Charisma'
    default:
      return label
  }
}
