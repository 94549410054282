import { ChangeEvent } from 'react'

interface TextInputProps {
  id: string
  label: string
  placeholder: string
  name?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

function TextInput({ id, label, placeholder, name, onChange }: TextInputProps) {
  return (
    <div className='flex flex-col w-full xl:w-auto'>
      <label htmlFor={id} className='mb-1 text-gray-700'>
        {label}
      </label>
      <input
        type='text'
        id={id}
        name={name}
        placeholder={placeholder}
        className='p-2 border border-gray-300 rounded'
        onChange={onChange}
      />
    </div>
  )
}

export default TextInput
