import FilterDropdown from './FilterDropdown'
import { CsvData } from '../types/CsvData'
import { dataHeaderToColumn } from '../utils/dataHeaderToColumn'

interface FilterMenuProps {
  data: CsvData
  handleFilterChange: (filter: React.ChangeEvent<HTMLSelectElement>) => void
}

function prepareFilterOptions(data: CsvData) {
  const optionsToRemove = ['Name', 'URL']
  const headers = data.headers.filter(
    (header) => !optionsToRemove.includes(header)
  )

  const filterOptions = headers.map((header) => ({
    id: header,
    label: header,
    options: Array.from(new Set(dataHeaderToColumn(data, header))),
  }))

  filterOptions.forEach((option) => {
    if (option.id === 'CR') {
      option.options.sort((a, b) => {
        const numA = a.includes('/')
          ? parseFloat(a.split('/')[0]) / parseFloat(a.split('/')[1])
          : parseFloat(a)
        const numB = b.includes('/')
          ? parseFloat(b.split('/')[0]) / parseFloat(b.split('/')[1])
          : parseFloat(b)
        return numA - numB
      })
    } else if (option.id === 'Name') {
      option.options.sort()
    } else {
      option.options.sort((a, b) => Number(a) - Number(b))
    }
  })

  return filterOptions
}

function FilterMenu({ data, handleFilterChange }: FilterMenuProps) {
  return (
    <>
      <div className='grid grid-rows-4 md:grid-rows-3 lg:grid-rows-2 xl:grid-rows-1 grid-flow-col gap-x-4 gap-y-2'>
        {prepareFilterOptions(data).map((filter) => (
          <FilterDropdown
            id={filter.id}
            label={filter.label}
            key={filter.id}
            options={filter.options}
            handleFilterChange={handleFilterChange}
          />
        ))}
      </div>
    </>
  )
}

export default FilterMenu
