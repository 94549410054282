import React, { useCallback, useEffect, useState } from 'react'
import './App.css'
import { CsvData } from './types/CsvData'
import FilterMenu from './components/FilterMenu'
import DataTable from './components/DataTable'
import TextInput from './components/TextInput'

interface Filters {
  name: string
  cr: string
  size: string
  str: string
  ges: string
  kon: string
  int: string
  wei: string
  cha: string
}

function App() {
  const [data, setData] = useState<CsvData>({ headers: [], rows: [] })
  const [filteredData, setFilteredData] = useState<CsvData>({
    headers: [],
    rows: [],
  })
  const [currentSort, setCurrentSort] = useState({ column: '', direction: '' })
  const [filters, setFilters] = useState({
    name: '',
    cr: '',
    size: '',
    str: '',
    ges: '',
    kon: '',
    int: '',
    wei: '',
    cha: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://gist.githubusercontent.com/kihayu/b10923b589138c5bceedd463afe9dd0a/raw/6ffa27e231c6149196163ee2d81ac92aa5e7338e/gistfile1.txt'
        )
        const text = await response.text()
        const headers = text.split('\n')[0].split(';')
        const rows = text
          .split('\n')
          .slice(1)
          .map((row) => row.split(';'))
        setData({ headers, rows })
      } catch (error) {
        console.error('Error fetching the CSV data:', error)
      }
    }

    fetchData()
  }, [])

  const availableData = useCallback(
    () =>
      filteredData.headers.length > 0 && filteredData.rows.length > 0
        ? filteredData
        : data,
    [filteredData, data]
  )

  const setDataBasedOnFilters = (filters: Filters) => {
    const filtered = data.rows.filter((row) => {
      return Object.entries(filters).every(
        ([_, value], index) =>
          !value || row[index].toLowerCase().includes(value.toLowerCase())
      )
    })
    setFilteredData({ headers: availableData().headers, rows: filtered })
  }

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target

    const updatedFilters = { ...filters, [name.toLowerCase()]: value }
    setFilters(updatedFilters)
    setDataBasedOnFilters(updatedFilters)
  }

  const handleSort = (column: string) => {
    let direction = 'asc'
    if (currentSort.column === column) {
      // Toggle sort direction: asc -> desc -> default
      if (currentSort.direction === 'asc') {
        direction = 'desc'
      } else if (currentSort.direction === 'desc') {
        direction = ''
      }
    }

    setCurrentSort({ column, direction })

    if (direction !== '') {
      const sortedRows = [...availableData().rows].sort((a, b) => {
        const aValue = a[data.headers.indexOf(column)]
        const bValue = b[data.headers.indexOf(column)]

        if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
          return direction === 'asc'
            ? Number(aValue) - Number(bValue)
            : Number(bValue) - Number(aValue)
        } else {
          return direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        }
      })

      setFilteredData({ headers: data.headers, rows: sortedRows })
      return
    }

    const isAnyFilterSet = Object.values(filters).some((value) => value !== '')
    if (isAnyFilterSet) {
      setDataBasedOnFilters(filters)
    } else {
      setFilteredData({ headers: data.headers, rows: data.rows })
    }
  }

  return (
    <div className='container mx-auto p-6'>
      <h1 className='text-2xl font-bold mb-4 text-center'>
        D&D - Druiden Biester
      </h1>

      <div className='flex flex-col space-y-4 mb-4'>
        <div className='flex flex-row'>
          <TextInput
            id='nameFilter'
            label='Name'
            placeholder='Filter nach Name'
            onChange={handleFilterChange}
          />
        </div>

        <FilterMenu
          data={availableData()}
          handleFilterChange={handleFilterChange}
        />

        <DataTable
          data={availableData()}
          currentSort={currentSort}
          handleSort={handleSort}
        />
      </div>
    </div>
  )
}

export default App
