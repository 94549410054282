import { CsvData } from '../types/CsvData'
import { shortLabelToFullLabel } from '../utils/shortLabelToFullLabel'

interface DataTableProps {
  data: CsvData
  currentSort: { column: string; direction: string }
  handleSort: (column: string) => void
}

function shownTableData(data: CsvData) {
  const optionsToRemove = ['Name', 'URL']
  return data.headers.filter((header) => !optionsToRemove.includes(header))
}

function DataTable({ data, currentSort, handleSort }: DataTableProps) {
  const sortIcon = (column: string) => {
    if (currentSort.direction !== '' && currentSort.column === column) {
      return currentSort.direction === 'asc' ? '\u00A0▲' : '\u00A0▼'
    }
    return ''
  }

  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full bg-white'>
        <thead>
          <tr>
            <th
              className='py-2 px-4 border border-r-gray-300 cursor-pointer select-none bg-gray-200 hover:bg-gray-300'
              onClick={() => handleSort('Name')}
            >
              Name
              {sortIcon('Name')}
            </th>
            {shownTableData(data).map((header) => (
              <th
                className='py-2 px-4 border border-r-gray-300 last:border-r-0 cursor-pointer select-none bg-gray-200 hover:bg-gray-300'
                onClick={() => handleSort(header)}
                key={header}
              >
                {shortLabelToFullLabel(header)}
                {sortIcon(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((creature) => (
            <tr key={creature[0]}>
              <td className='py-2 px-4 border text-center'>
                {creature[9] ? (
                  <a
                    href={creature[9]}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 hover:text-blue-800'
                  >
                    {creature[0]}
                  </a>
                ) : (
                  creature[0]
                )}
              </td>
              {/* <td className='py-2 px-4 border text-center'>{creature[1]}</td> */}
              {shownTableData(data).map((header) => (
                <td className='py-2 px-4 border text-center' key={header}>
                  {creature[data.headers.indexOf(header)]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
